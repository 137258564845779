import React from 'react';
import { Link, graphql } from 'gatsby';
import { Alert, AlertTitle, Box, Typography, Chip, Stack, Fab } from '@mui/material';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import { MeteoInterface } from '../../../model/Meteo/MeteoInterface';
import { Ville } from '../../../model/Ville';
import { drapeaux } from '../../../model/Meteo/Drapeau/drapeaux';
import { PlageNodeInterface } from '../../../infrastructure/model/Gatsby/PlageNodeInterface';
import { MarkdownRemarkPageDataInterface } from '../../../infrastructure/model/Gatsby/MarkdownRemarkPageDataInterface';
import CustomizedPageHelmet from '../../../components/CustomizedPageHelmet';
import Layout from '../../../components/Layout';
import DrapeauSvgIcon from '../../../components/icon/svg/DrapeauSvgIcon';
import MeteoCards from '../../../components/MeteoCards';
import { renderHast, splitHastUntil } from '../../../infrastructure/hast';
import { TypeDeDrapeau } from '../../../model/Meteo/Drapeau/TypeDeDrapeau';

export interface PlagePageProps {
  data: {
    site: Queries.Site;
    plage: PlageNodeInterface & { meteo: MeteoInterface & { derniereMiseAJour: string } };
    markdownRemark?: MarkdownRemarkPageDataInterface;
  };
  params: {
    nom: string;
  };
  pageContext: { id: string };
}

const PlagePage = ({
  data: {
    plage: { nom, ville, tags, meteo },
    markdownRemark,
    site,
  },
}: PlagePageProps) => {
  const [firstHalf, secondHalf] = splitHastUntil(markdownRemark?.htmlAst, ({ tagName }) => tagName === 'h2');
  const drapeau = meteo?.typeDeDrapeau ? drapeaux[meteo?.typeDeDrapeau] : null;

  return (
    <Layout>
      <CustomizedPageHelmet metadata={markdownRemark?.frontmatter} defaultTitle={site?.siteMetadata?.title} />

      {firstHalf ? (
        renderHast(firstHalf)
      ) : (
        <>
          <Typography variant="h1">{nom}</Typography>
          <Typography variant="subtitle1" component={Link} to={Ville.getPath(ville)}>{ville.nom}</Typography>
        </>
      )}

      {(meteo?.alertes.length > 0 || meteo?.typeDeDrapeau) && (
        <Stack sx={{ width: '100%' }} py={2} spacing={2}>
          {meteo?.alertes.length > 0 && (
            <>
              {meteo?.alertes.map((alerte) => (
                <Alert key={alerte} severity="error">
                  <AlertTitle>{alerte}</AlertTitle>
                </Alert>
              ))}
            </>
          )}

          {drapeau && (
            <Alert icon={<DrapeauSvgIcon drapeau={drapeau} />} color={TypeDeDrapeau[drapeau.typeDeDrapeau]}>
              <AlertTitle>{drapeau.signification}</AlertTitle>
              Niveau de risque : {drapeau.niveauDeRisque}
            </Alert>
          )}
        </Stack>
      )}

      {meteo && <MeteoCards meteo={meteo} />}

      <Box py={2}>
        <Typography variant="h2">Services sur place</Typography>
        {tags.length > 0 && (
          <Stack direction="row" spacing={2}>
            {tags.map((tag) => (
              <Chip key={tag} label={tag} variant="outlined" />
            ))}
          </Stack>
        )}
      </Box>

      {secondHalf && <Box py={2}>{renderHast(secondHalf)}</Box>}

      <Fab
        variant="extended"
        color="primary"
        aria-label={`Voir les autres plages de ${ville.nom}`}
        component={Link}
        to={Ville.getPath(ville)}
        sx={{
          position: 'fixed',
          right: 20,
          bottom: 20,
        }}
      >
        <BeachAccessIcon sx={{ mr: 1 }} />
        Autres plages
      </Fab>
    </Layout>
  );
};
export default PlagePage;

export const query = graphql`
  query ($id: String) {
    plage(id: { eq: $id }) {
      frequenceSudId
      nom
      email
      siteWeb
      telephone
      ville {
        departement
        nom
      }
      coordinates {
        latitude
        longitude
      }
      tags
      meteo {
        derniereMiseAJour
        temperature
        commentaire
        alertes
        typeDeDrapeau
        eau {
          qualite
          temperature
        }
        merEtat
        openWeatherMapCode
        plageId
        sourceType
        vent {
          vitesse
          direction
        }
      }
    }
    markdownRemark(fields: { plage: { id: { eq: $id } } }) {
      html
      htmlAst
      frontmatter {
        description
        title
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;
