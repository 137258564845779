import React from 'react';
import { DrapeauInterface } from '../../../model/Meteo/Drapeau/DrapeauInterface';
import { TypeDeDrapeau } from '../../../model/Meteo/Drapeau/TypeDeDrapeau';
import { drapeaux } from '../../../model/Meteo/Drapeau/drapeaux';

export default function DrapeauSvgIcon({ drapeau = drapeaux[TypeDeDrapeau.Vert] }: { drapeau: DrapeauInterface }) {
  return (
    <svg width="24" height="45" viewBox="0 0 24 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4 32.8378V25.8378"
        stroke={drapeau.codeCouleur}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 25.8378C4 25.8378 5 24.8378 8 24.8378C11 24.8378 13 26.8378 16 26.8378C19 26.8378 20 25.8378 20 25.8378V13.8378C20 13.8378 19 14.8378 16 14.8378C13 14.8378 11 12.8378 8 12.8378C5 12.8378 4 13.8378 4 13.8378V25.8378Z"
        fill={drapeau.codeCouleur}
        stroke={drapeau.codeCouleur}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
