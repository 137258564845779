import { VilleInterface } from './VilleInterface';
import { CoordinatesInterface } from './CoordinatesInterface';
import { slugify } from '../infrastructure/slugify';

export class Ville implements VilleInterface {
  public id: string;
  public nom: string;
  public codePostal: number;
  public coordinates: CoordinatesInterface;
  public departement: string;

  public constructor({ id, nom, codePostal, coordinates, departement }: VilleInterface) {
    this.id = id;
    this.nom = nom;
    this.codePostal = codePostal;
    this.coordinates = coordinates;
    this.departement = departement;
  }

  public getNormalizedNom() {
    return Ville.normalizeNom(this.nom);
  }

  public static computeId({ departement, nom }: Pick<Ville, 'departement' | 'nom'>) {
    return `Ville-${departement}:${Ville.normalizeNom(nom)}`;
  }

  public static normalizeNom(nom: string) {
    return slugify(nom);
  }

  getPath() {
    return Ville.getPath(this);
  }

  static getPath({ departement, nom }: Pick<Ville, 'departement' | 'nom'>) {
    return `/${departement}/${Ville.normalizeNom(nom)}`;
  }
}
