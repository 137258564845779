import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Alert, Box, Divider, Grid, Paper } from '@mui/material';
import MeteoSvgIcon from './icon/svg/MeteoSvgIcon';
import AirSvgIcon from './icon/svg/AirSvgIcon';
import { Meteo } from '../model/Meteo/Meteo';
import EauSvgIcon from './icon/svg/EauSvgIcon';
import VentSvgIcon from './icon/svg/VentSvgIcon';
import { Vent } from '../model/Meteo/Vent';
import NavigationIcon from '@mui/icons-material/Navigation';
import EtatDeLaMerSvgIcon from './icon/svg/EtatDeLaMerSvgIcon';
import { MeteoInterface } from '../model/Meteo/MeteoInterface';
import { PaperTypeMap } from '@mui/material/Paper/Paper';

const MeteoCard = ({ children, ...props }: PaperTypeMap['props']) => {
  return (
    <Paper
      sx={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      {...props}
    >
      {children}
    </Paper>
  );
};

const CompositeMeteoCard = ({ children, sx, ...props }: PaperTypeMap<Box>['props']) => {
  return (
    <Paper
      sx={{
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        ...sx,
      }}
      component={Box}
      py={1}
      {...props}
    >
      {children}
    </Paper>
  );
};

export default function MeteoCards({ meteo }: { meteo: MeteoInterface & { derniereMiseAJour: string } }) {
  return (
    <Box paddingBottom={2}>
      <Grid container spacing={2} minHeight={188}>
        <Grid item xs={4}>
          <MeteoCard>
            <img
              src={`https://www.frequence-sud.fr/img/navigation/ux/meteo/icon2/d${meteo.openWeatherMapCode}.png`}
              style={{ maxWidth: '50px' }}
            />
          </MeteoCard>
        </Grid>

        <Grid item xs={4} sx={{ display: 'flex' }} flexDirection="column">
          <CompositeMeteoCard marginBottom={2} flexGrow={{ flexGrow: 1 }}>
            <Box sx={{ display: 'flex' }} flexDirection="column" alignItems="center">
              <AirSvgIcon />
              <Typography variant="body1">Air</Typography>
            </Box>
            <Divider orientation="vertical" flexItem />
            <Typography variant="body1">
              <strong>{Meteo.formatTemperature(meteo?.temperature) || 'N/A'}</strong>
            </Typography>
          </CompositeMeteoCard>

          <CompositeMeteoCard flexGrow={{ flexGrow: 1 }}>
            <Box sx={{ display: 'flex' }} flexDirection="column" alignItems="center">
              <EauSvgIcon />
              <Typography variant="body1">Eau</Typography>
            </Box>
            <Divider orientation="vertical" flexItem />
            <Typography variant="body1">
              <strong>{Meteo.formatTemperature(meteo?.eau?.temperature) || 'N/A'}</strong>
            </Typography>
          </CompositeMeteoCard>
        </Grid>

        <Grid item xs={4}>
          <CompositeMeteoCard flexDirection="column" sx={{ height: '100%' }}>
            <Typography variant="body1">Vitesse</Typography>
            <VentSvgIcon />
            <Typography variant="body1">
              <strong>{meteo.vent && Vent.getVitesseFormatee(meteo.vent)}</strong>
            </Typography>

            <Divider variant="middle" flexItem />

            <Typography variant="body1">Direction</Typography>
            <NavigationIcon sx={{ transform: Vent.getCSSTransform(meteo.vent) }} htmlColor="#F1BC95" />
            <Typography variant="body1">
              <strong>{meteo.vent && Vent.getDirectionFormatee(meteo.vent)}</strong>
            </Typography>
          </CompositeMeteoCard>
        </Grid>

        <Grid item xs={12}>
          {(meteo.eau.qualite || meteo.merEtat) && (
            <Alert variant="outlined" icon={<EtatDeLaMerSvgIcon />} severity="info">
              {meteo.merEtat && (
                <Typography variant="body1">
                  Etat de la mer : <strong>{meteo.merEtat}</strong>
                </Typography>
              )}
              {meteo.eau.qualite && (
                <Typography variant="body1">
                  Qualité de l&apos;eau : <strong>{meteo.eau.qualite}</strong>
                </Typography>
              )}
            </Alert>
          )}
        </Grid>
      </Grid>

      <Typography variant="body2">
        <em>Dernière mise à jour {Meteo.getTempsDepuisDerniereMisAjour(meteo.derniereMiseAJour)}.</em>
      </Typography>
    </Box>
  );
}
