import { formatDistance, parseISO } from 'date-fns';
import { fr } from 'date-fns/locale';
import { PlageInterface } from '../PlageInterface';
import { MeteoInterface } from './MeteoInterface';
import { MeteoPlageAlerteType } from './MeteoPlageAlerteType';
import { TypeDeDrapeau } from './Drapeau/TypeDeDrapeau';
import { EtatMer } from './EtatMer';
import { MeteoSourceType } from './MeteoSourceType';
import { VentInterface } from './VentInterface';
import { EauInterface } from './EauInterface';

export class Meteo implements MeteoInterface {
  alertes: MeteoPlageAlerteType[];
  commentaire: string;
  derniereMiseAJour: Date;
  typeDeDrapeau?: TypeDeDrapeau;
  eau: EauInterface;
  merEtat?: EtatMer;
  openWeatherMapCode: number;
  plageId: PlageInterface['id'];
  sourceType: MeteoSourceType;
  temperature: number;
  vent: VentInterface;

  public constructor(meteo: MeteoInterface) {
    this.alertes = meteo.alertes;
    this.commentaire = meteo.commentaire;
    this.derniereMiseAJour = meteo.derniereMiseAJour;
    this.typeDeDrapeau = meteo.typeDeDrapeau;
    this.eau = meteo.eau;
    this.merEtat = meteo.merEtat;
    this.openWeatherMapCode = meteo.openWeatherMapCode;
    this.sourceType = meteo.sourceType;
    this.temperature = meteo.temperature;
    this.vent = meteo.vent;
    this.plageId = meteo.plageId;
  }

  static getTempsDepuisDerniereMisAjour(derniereMiseAJour: string) {
    return formatDistance(parseISO(derniereMiseAJour), new Date(), { locale: fr, addSuffix: true });
  }

  static formatTemperature(temperature?: number | string) {
    return temperature ? `${temperature}°C` : '';
  }
}
