import { VentInterface } from './VentInterface';

export class Vent implements VentInterface {
  public vitesse: number;
  public direction: number;

  public constructor({ vitesse, direction }: VentInterface) {
    this.vitesse = vitesse;
    this.direction = direction;
  }

  static getVitesseFormatee({ vitesse }: Pick<VentInterface, 'vitesse'>) {
    return vitesse ? `${vitesse} km/h` : '';
  }

  static getCSSTransform({ direction }: Pick<VentInterface, 'direction'>) {
    return `rotate(${direction ? direction : 0}deg)`;
  }

  static getDirectionFormatee({ direction }: Pick<VentInterface, 'direction'>) {
    return direction ? `${direction}°` : '';
  }

  static toString(vent: VentInterface) {
    return [Vent.getVitesseFormatee(vent), Vent.getDirectionFormatee(vent)].filter((a) => a).join(' ');
  }

  public toString() {
    return Vent.toString(this);
  }
}
