import React from 'react';

export default function AirSvgIcon() {
  return (
    <svg width="39" height="40" viewBox="0 0 39 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.37309 10.5999C7.38576 12.233 10.3225 15.1726 13.9681 13.8661C18.525 12.233 20.8035 8.15022 26.12 8.15022C30.3732 8.15022 32.4492 10.3277 32.9555 11.4164"
        stroke="#91ABBA"
        strokeWidth="2.41658"
        strokeLinecap="round"
      />
      <path
        d="M6.37309 19.0579C7.38576 20.691 10.3225 23.6306 13.9681 22.3241C18.525 20.691 20.8035 16.6083 26.12 16.6083C30.3732 16.6083 32.4492 18.7857 32.9555 19.8745"
        stroke="#91ABBA"
        strokeWidth="2.41658"
        strokeLinecap="round"
      />
      <path
        d="M6.37309 27.5159C7.38576 29.1491 10.3225 32.0886 13.9681 30.7822C18.525 29.1491 20.8035 25.0663 26.12 25.0663C30.3732 25.0663 32.4492 27.2438 32.9555 28.3325"
        stroke="#91ABBA"
        strokeWidth="2.41658"
        strokeLinecap="round"
      />
    </svg>
  );
}
